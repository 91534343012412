@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,400;0,700;1,400;1,700&family=IBM+Plex+Sans:ital,wght@0,400;0,700;1,400;1,700&family=IBM+Plex+Serif:ital,wght@0,400;1,400&display=swap');

:root {
  --magenta-base: 234, 5, 176;
  --violet-base: 180, 0, 255;
  --purple-base: 152, 0, 254;
  --indigo-base: 127, 36, 254;
  --azure-base: 0, 150, 254;
  --cyan-base: 11, 214, 254;

  --magenta: rgb(var(--magenta-base));
  --violet: rgb(var(--violet-base));
  --purple: rgb(var(--purple-base));
  --indigo: rgb(var(--indigo-base));
  --azure: rgb(var(--azure-base));
  --cyan: rgb(var(--cyan-base));

  --grey0: #fafafa;
  --grey1: #ccc;
  --grey2: #ddd;
  --grey3: #777;
  --grey4: #222;

  --sans: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  --serif: 'IBM Plex Serif', serif;
  --mono: 'IBM Plex Mono', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;

  --radius: 0.125rem;
  --shadow: 3px rgba(100, 100, 100, 0.5);
  --shadow-color: 0 rgba(var(--magenta-base), 0.5);

  --nav-height: 70px;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: var(--sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--mono);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--mono);
}

a {
  color: #000;
  text-decoration-color: rgba(var(--magenta-base), 0.7);
  text-decoration-thickness: 15%;
  transition: all 250ms;
  &:visited {
    color: var(--indigo);
  }
  &:hover {
    text-decoration-color: black;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px var(--grey2) inset;
  -webkit-box-shadow: 0 0 0px 1000px var(--grey2) inset;
  transition: background-color 5000s ease-in-out 0s;
}
