#container {
  &.logged-out {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
#login-container {
  background-color: #fff;
  max-width: 460px;
  padding: 4rem;
  border-radius: var(--radius);
  text-align: center;
  img {
    max-width: 30%;
  }
  h1 {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }
  button {
    min-width: 60%;
    display: inline-block;
    margin-top: 2rem;
  }
}
#logout-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15%;
  min-width: 15%;
  max-width: 15%;
  button {
    width: 100%;
    background-color: white;
    color: black;
    border: none;
    border-top: 1px solid var(--grey1);
    justify-content: left;
    &:hover {
      color: var(--magenta);
    }
  }
}
.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--nav-height);
  background-color: #fff;
  border-bottom: 1px solid var(--grey1);
  box-shadow: 0 0 var(--shadow);
  display: flex;
  justify-content: flex-start;
  z-index: 9999;

  > div {
    padding: 1rem;
  }

  > div:first-of-type {
    width: 15%;
    min-width: 15%;
    max-width: 15%;
  }

  > div:nth-of-type(2) {
    width: 45%;
    min-width: 45%;
    max-width: 45%;
  }

  > div:last-of-type {
    width: 40%;
    min-width: 40%;
    max-width: 40%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  img {
    display: block;
    &:hover {
      cursor: pointer;
    }
  }
}

#content-search {
  font-size: 1.6rem;
  padding: 0.4rem;
  width: 100%;
  font-family: var(--mono);
  border: 1px solid var(--grey3);
  border-radius: var(--radius);
  background-color: var(--grey2);
  &:focus {
    outline: var(--violet);
    box-shadow: 0px 0px 0.25em var(--magenta);
  }
}

.main {
  display: flex;
  width: 100%;
  min-height: 100%;
  margin-top: 70px;
  justify-content: space-between;
}

#filters {
  padding: 0 1rem;
  width: 15%;
  min-width: 15%;
  max-width: 15%;
  max-height: calc(100vh - var(--nav-height) - 36px);
  padding-bottom: 1rem;
  overflow: auto;

  h4 {
    margin: .75rem 0 0.25rem;
    text-transform: uppercase;
    cursor: pointer;

    &+ul {
      overflow: hidden;
      height: auto;
      max-height: 100vh;
      transition: max-height 0.3s ease-out;
    }
  
    &.collapsed+ul {
      max-height: 0;
    }
  }

  .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    margin: 3px 3px;
    transform: rotate(45deg);
  }

  .collapsed .arrow {
    transform: rotate(225deg);
    margin: 1px 3px;
  }

  #content-types-filter li:nth-of-type(2) {
    border-bottom: 1px solid #eeeeee;//var(--grey1);
    padding-bottom: 0.5rem;
    margin-bottom: 0.25rem;
  }
}

#content-list {
  position: relative;
  width: 45%;
  min-width: 45%;
  max-width: 45%;
  min-height: calc(100vh - var(--nav-height) * 2);
  max-height: calc(100vh - var(--nav-height) * 2);
  overflow: auto;
  padding: 0 1rem;
  border-left: 1px solid var(--grey1);
  border-right: 1px solid var(--grey1);
  background-color: var(--grey0);

  header {
    display:flex;
    flex-wrap:nowrap;
    justify-content: space-between;
    align-items: center;

    button.mini {
      text-decoration:underline;
      padding-bottom:5px;
    }
  }

  h1 {
    height: var(--nav-height);
    margin: 0;
    display: flex;
    align-items: center;
  }

  section {
    margin-bottom: 1rem;
  }
}

#manage-experiments #content-list {
  min-height: calc(100vh - var(--nav-height));
  max-height: calc(100vh - var(--nav-height));
}

article.result {
  margin: 0 0 1rem 0;
  display: flex;
  justify-content: space-between;

  .summary-container {
    width: calc(100% - 40px);
    padding: 0 1rem 1rem;
    border-radius: var(--radius);
    border: 1px solid var(--grey1);
    background-color: #fff;
    transition: all 250ms;

    &.selected {
      box-shadow: 2px 2px var(--cyan);
    }

    &:hover {
      cursor: pointer;
      box-shadow: 2px 2px var(--shadow-color);
      // border:1px solid var(--magenta);
      border: 1px solid black;
    }
  }

  h3 {
    word-wrap: break-word;
  }

  span {
    text-transform: uppercase;
    display: inline-block;
    margin-right: 2rem;
  }
}

.menu-container {
  ul {
    display: none;

    li:first-of-type {
      display: inline-block;
      font-family: var(--mono);
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }
  }
  &.open {
    ul {
      display: block;
      position: absolute;
      right: 1rem;
      background-color: white;
      padding: 1rem;
      border: 1px solid black;
      border-radius: var(--radius);
      margin-top: -1px;
      box-shadow: 2px 2px var(--shadow);
    }
  }
}

#preview-panel {
  width: 40%;
  min-width: 40%;
  max-width: 40%;
  max-height: calc(100vh - var(--nav-height));
  overflow: auto;
  //background-color: rgba(var(--cyan-base),.1);

  figure {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  pre {
    max-width: 100%;
    max-height:400px;
    overflow: auto;
    background-color: var(--grey4);
    color: var(--cyan);
    padding: 0.25rem;
    white-space: pre;
    margin: 0;
    tab-size: 2;
  }
}

#more-post-data {
  background-color: var(--grey2);
  padding: 0.5rem 1rem 1rem;
  margin: 1rem;
  border: 1px solid var(--grey1);
  border-radius: var(--radius);
  
  h4 {
    display: block;
    font-weight: bold;
    margin: 0.5rem 0;
  } 

  table {
    border-collapse: collapse;
  }

  th {
    padding: 0 0.5rem 0 0;
    vertical-align: top;
    white-space: nowrap;
    padding-bottom: 10px;
    text-align: left;
    
  }

  td {
    padding: 0;
    vertical-align: top;
    line-break:anywhere;
    padding-bottom: 11px;
  }
}

.posts-nav {
  position: fixed;
  left: 15%;
  bottom: 0;
  width: 45%;
  height: var(--nav-height);
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid var(--grey1);
  border-bottom: none;
}

.posts-nav-position {
  min-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;

  input {
    max-width:50px;
    margin:0 .25rem;
  }

  button {
    max-height: 2rem;
    margin:0 0 0 .5rem;
    padding: 0;
  }
}

.post-actions {
  position: sticky;
  top: 0;
  width: 100%;
  min-height: var(--nav-height);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 1rem 0;
  padding: 1rem;
  background-color: white;
  border-bottom: 1px solid var(--grey1);
  box-shadow: 0 0 var(--shadow);

  > div {
    min-width: 50%;
    display: flex;
    justify-content: right;
  }

  a.button {
    margin: 0 0 0 1rem;
  }

  button.cta {
    visibility: hidden;
  }

  ul {
    min-width: calc(100% + 2rem);
    background-color: white;
    margin-left: -1rem;
    padding: 1rem 1rem 0 1rem;
    display: none; //flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    li {
      padding-right: 1rem;
    }
  }

  input[type='checkbox'] {
    display: none;
    & + label {
      border: 1px solid var(--grey3);
      padding: 0.25rem;
      line-height: 2.75;
      border-radius: var(--radius);
    }
    &:checked + label {
      border: 1px solid var(--grey3);
      background-color: var(--grey2);
    }
  }
}

article.preview {
  padding: 0 1rem 1rem;
  margin: 1rem;
  border-radius: var(--radius);
  box-shadow: 2px 2px var(--shadow);
  border: 1px solid var(--grey1);
  background-color: #fff;

  > div {
    font-family: var(--serif);
    line-height: 1.5;
  }

  h2 {
    overflow-wrap: break-word;
  }

  .post-info {
    background-color: var(--grey2);
    padding: 0.25rem 1rem;
    margin: 0 -1rem 1rem -1rem;
    span {
      text-transform: uppercase;
      display: inline-block;
      margin-right: 2rem;
    }
  }

  .byline {
    text-transform: uppercase;
    font-family: var(--sans);
    .ng_byline_email {
      display: none;
    }
    p {
      display: inline;
    }
  }
}

button,
a.button {
  min-width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  font-weight: normal;
  font-family: var(--mono);
  border-radius: var(--radius);
  background-color: black;
  color: #fff;
  border: 1px solid #000;
  text-decoration: none;
  transition: all 250ms ease;

  &:hover {
    cursor: pointer;
    box-shadow: 2px 2px var(--shadow-color);
  }

  &:disabled {
    color: var(--grey2);
  }

  &.cta {
    background-color: var(--cyan);
    color: #000;

    &:hover {
      color: #fff;
      background-color: var(--cyan);
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      background-image: linear-gradient(
        130deg,
        var(--cyan),
        var(--azure),
        var(--magenta)
      );
      background-size: 200% 200%;

      -webkit-animation: Animation 10s ease;
      -moz-animation: Animation 10s ease;
      animation: Animation 10s ease;
    }
  }

  &.reverse {
    background-color: #fff;
    color: #000;

    &:hover {
      box-shadow: 2px 2px var(--shadow-color);
    }
  }

  &.mini {
    width: 30px;
    min-width: 30px;
    height: 30px;
    padding: 0 0 2px 0;
    font-size: 1.75rem;
    line-height: 0;
    background-color: #000;

    &:hover {
      box-shadow: none;
      background-color: #000;
      //background: linear-gradient(130deg, var(--magenta), var(--indigo), var(--cyan), var(--indigo), var(--magenta));
      background-image: linear-gradient(
        130deg,
        var(--magenta),
        var(--indigo),
        var(--azure)
      );
      background-size: 300% 300%;

      -webkit-animation: Animation 10s ease infinite;
      -moz-animation: Animation 10s ease infinite;
      animation: Animation 10s ease infinite;
    }

    &.reverse {
      background-color:#fff;
      color:#000;
      &:hover {
        background-image:none;
        background-color:#fff;
        box-shadow:2px 2px var(--shadow-color);
      }
    }
  }
}

a.button button:hover {
  box-shadow: none;
}

input[type='checkbox'],
input[type='radio'] {
  appearance: none;
  -webkit-appearance: none;
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem -0.25rem 0;
  transition: box-shadow 0.3s;
  background: var(--grey1);
  border: 1px solid var(--grey3);

  &:hover {
    cursor: pointer;
  }
}

input[type='checkbox'] {
  border-radius: var(--radius);
}
input[type='radio'] {
  border-radius: 50%;
}

input[type='checkbox']:checked,
input[type='radio']:checked {
  box-shadow: inset 0 0 0 20px var(--cyan);
  border: 1px solid black;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  margin-bottom: 0.25rem;
  &:hover {
    cursor: pointer;
  }
}



.gradient-bg {
  background: linear-gradient(
    45deg,
    var(--magenta),
    var(--violet),
    var(--purple),
    var(--indigo),
    var(--azure),
    var(--cyan)
  ); //linear-gradient(130deg, var(--magenta), var(--indigo), var(--cyan), var(--indigo), var(--magenta));
  background-size: 200% 200%;

  -webkit-animation: Animation 10s ease infinite;
  -moz-animation: Animation 10s ease infinite;
  animation: Animation 10s ease infinite;
}

@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

.search-only {
  .nav-bar .button,
  .menu-container {
    display:none;
  }
  article.result .summary-container {
    width:100%;
  }
}

.cnx-video-tag{
  content: "";
	width: 100%;
	background-color: #000000;
	z-index: 9;
	transition: opacity 0.3s ease-in-out;
}

a:has(.cnx-video-tag){
  display: block;
  position: relative;
  color: var(--magenta);
  text-shadow: 1px 1px 3px black;

  &:hover{
    color: var(--cyan);
  }
}

a:has(.cnx-video-tag)::after{
  background-color: rgba(0,0,0,0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  content: "\25B6";
  font-size: 5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

